import Image from 'next/image';
import Link from 'next/link';
import { usePlausible } from 'next-plausible';
import { urlFor } from '@lib/sanity';

const Marketing = ({ infoRows: { rows } }) => {
  const plausible = usePlausible();
  const firstRow = rows[0];
  const secondRow = rows[1];
  return (
    <div className="relative overflow-hidden pt-16 pb-32">
      <div
        aria-hidden="true"
        className="absolute inset-x-0 top-0 h-48 bg-gradient-to-b from-gray-100"
      />
      <div className="relative">
        <div className="lg:mx-auto lg:grid lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-2 lg:gap-24 lg:px-8">
          <div className="mx-auto max-w-xl px-4 sm:px-6 lg:mx-0 lg:max-w-none lg:py-16 lg:px-0">
            <div>
              <div>
                <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                  {firstRow.title}
                </h2>
                <p className="mt-4 text-lg text-gray-500">{firstRow.text}</p>
                <div className="mt-6">
                  <Link
                    href="/electrical-services"
                    prefetch={false}
                    onClick={() =>
                      plausible('marketingCta', {
                        props: { name: 'Electrical Services' },
                      })
                    }
                    className="inline-flex rounded-md border border-transparent bg-gradient-to-r from-rose-600 to-red-600 bg-origin-border px-4 py-2 text-base font-medium text-white shadow-sm hover:from-rose-700 hover:to-red-700"
                  >
                    Services
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-12 sm:mt-16 lg:mt-0">
            <div className="-mr-48 pl-4 sm:pl-6 md:-mr-16 lg:relative lg:m-0 lg:h-full lg:px-0">
              <div className="relative h-96 w-full">
                <Image
                  className="rounded-xl object-cover shadow-xl ring-1 ring-black ring-opacity-5"
                  src={urlFor(firstRow.illustration.image).url()}
                  alt={firstRow.illustration.image.alt}
                  fill
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-24">
        <div className="lg:mx-auto lg:grid lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-2 lg:gap-24 lg:px-8">
          <div className="mx-auto max-w-xl px-4 sm:px-6 lg:col-start-2 lg:mx-0 lg:max-w-none lg:py-16 lg:px-0">
            <div>
              <div>
                <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                  {secondRow.title}
                </h2>
                <p className="mt-4 text-lg text-gray-500">{secondRow.text}</p>
                <div className="mt-6">
                  <Link
                    href="/projects"
                    prefetch={false}
                    onClick={() =>
                      plausible('marketingCta', {
                        props: { name: 'Projects' },
                      })
                    }
                    className="inline-flex rounded-md border border-transparent bg-gradient-to-r from-rose-600 to-red-600 bg-origin-border px-4 py-2 text-base font-medium text-white shadow-sm hover:from-rose-700 hover:to-red-700"
                  >
                    Projects
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-12 sm:mt-16 lg:col-start-1 lg:mt-0">
            <div className="-ml-48 pr-4 sm:pr-6 md:-ml-16 lg:relative lg:m-0 lg:h-full lg:px-0">
              <div className="relative h-96 w-full">
                <Image
                  className="w-full rounded-xl object-cover shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none"
                  src={urlFor(secondRow.illustration.image).url()}
                  alt={secondRow.illustration.image.alt}
                  fill
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Marketing;
