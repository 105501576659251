import dynamic from 'next/dynamic';
import { NextSeo } from 'next-seo';
import Hero from '@components/home/Hero';
import Marketing from '@components/home/Marketing';
import Layout from '@components/common/Layout';
import { getHomePage } from '@lib/api';
import { urlFor } from '@lib/sanity';

const Metrics = dynamic(() => import('@components/home/Metrics'));
export default function Home({
  heading,
  tagline,
  image,
  infoRows,
  cta,
  metaTitle,
  metaDescription,
}) {
  return (
    <>
      <NextSeo
        titleTemplate="%s"
        title={metaTitle}
        description={metaDescription}
        canonical="https://www.hlbelectric.com"
        openGraph={{
          title: `${metaTitle}`,
          url: 'https://www.hlbelectric.com',
          description: metaDescription,
          images: [
            {
              url: urlFor(image).width(800).height(600).url(),
              width: 800,
              height: 600,
              alt: image.alt,
            },
          ],
        }}
      />
      <Hero heading={heading} tagline={tagline} image={image} />
      <Marketing infoRows={infoRows} />
      <Metrics />
      <div className="bg-white">
        <div className="max-w-4xl mx-auto py-16 px-4 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8 lg:flex lg:items-center lg:justify-between">
          <h2 className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            <span className="block">{cta.title}</span>
            <span className="block bg-gradient-to-r from-rose-600 to-red-600 bg-clip-text text-transparent">
              {cta.subtitle}
            </span>
          </h2>
          <div className="mt-6 space-y-4 sm:space-y-0 sm:flex sm:space-x-5">
            <a
              href={cta.route}
              className="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-red-800 bg-red-50 hover:bg-red-100"
            >
              {cta.buttonText}
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export async function getStaticProps() {
  const data = await getHomePage();

  const {
    hero: { heading, tagline, image },
    infoRows,
    cta,
    metaTags: { metaTitle, metaDescription },
  } = data[0];

  return {
    props: {
      heading,
      tagline,
      image,
      infoRows,
      cta,
      metaTitle,
      metaDescription,
    },
    revalidate: 86400,
  };
}

Home.Layout = Layout;
