import { FC } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { usePlausible } from 'next-plausible';
import { urlFor } from '@lib/sanity';

interface HeroProps {
  heading: string;
  tagline: string;
  image: {
    alt: string;
    image: object;
  };
}

const Hero: FC<HeroProps> = ({ heading, tagline, image }) => {
  const plausible = usePlausible();
  return (
    <div className="relative sm:pt-6 lg:pt-8">
      <div className="absolute inset-x-0 bottom-0 h-full bg-gray-100" />
      <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="relative shadow-xl sm:overflow-hidden sm:rounded-2xl">
          <div className="absolute inset-0">
            <Image
              src={urlFor(image).url()}
              alt={image.alt}
              fill
              className="relative h-full w-full object-cover"
            />
            <div className="absolute inset-0 bg-gradient-to-r from-gray-700 to-gray-600 mix-blend-multiply" />
          </div>
          <div className="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
            <h1 className="text-center text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl">
              <span className="block text-white">{heading}</span>
            </h1>
            <p className="mx-auto mt-6 max-w-lg text-center text-xl text-gray-200 sm:max-w-3xl">
              {tagline}
            </p>
            <div className="mx-auto mt-10 max-w-sm sm:flex sm:max-w-none sm:justify-center">
              <div className="space-y-4 sm:mx-auto sm:inline-grid sm:grid-cols-2 sm:gap-5 sm:space-y-0">
                <Link
                  href="/projects"
                  prefetch={false}
                  onClick={() =>
                    plausible('heroCta', { props: { name: 'Projects' } })
                  }
                  className="flex items-center justify-center rounded-md border border-transparent bg-white px-4 py-3 text-base font-medium text-red-700 shadow-sm hover:bg-red-50 sm:px-8"
                >
                  Projects
                </Link>
                <Link
                  href="/contact-us"
                  prefetch={false}
                  onClick={() =>
                    plausible('heroCta', { props: { name: 'Contact Us' } })
                  }
                  className="flex items-center justify-center rounded-md border border-transparent bg-red-600 bg-opacity-60 px-4 py-3 text-base font-medium text-white shadow-sm hover:bg-opacity-70 sm:px-8"
                >
                  Contact Us
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
